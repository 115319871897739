import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MinMaxEvComponent } from './MinMaxEv';
import { MinMaxOddsComponent } from './MinMaxOdds';
import { DatePickerComponent } from './DatePicker';
import { Delete } from '@mui/icons-material';
import { Tooltip, Checkbox } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { isMobile } from 'react-device-detect';

import './QueryRow.css';
export const animatedComponents = makeAnimated();

export const QueryRow = (props) => {
	const leagues = [];
	const sports = [];
	const sportsbooks = [];
	const markets = [];

	try {
		for (const league of props.filterOptions?.leagues) {
			leagues.push({
				value: league,
				label: league,
			});
		}
		for (const league of props.filterOptions?.sports) {
			sports.push({
				value: league,
				label: league,
			});
		}
		for (const league of props.filterOptions?.books) {
			sportsbooks.push({
				value: league,
				label: league,
			});
		}
		for (const league of props.filterOptions?.markets) {
			markets.push({
				value: league,
				label: league,
			});
		}
	} catch (e) {
		console.log('🚀 ~ QueryRow ~ e:', e);
	}

	return (
		<div
			style={{
				marginLeft: '2rem',
				marginRight: '2rem',
				borderBottomLeftRadius: props.last ? '12px' : '',
				borderBottomRightRadius: props.last ? '12px' : '',
				backgroundColor: '#f1f1f3',
				padding: '1.5rem',
				width: isMobile ? '1072px' : 'auto',
			}}>
			<div style={{ display: 'grid', gridTemplateColumns: '97% 3%' }}>
				<div style={{ display: 'grid', color: '#1a1a1d', gridTemplateColumns: 'auto auto auto auto auto' }}>
					<div style={{ maxWidth: '200px' }}>
						<label>Sports {props.queryRow.sport?.length ? `(${props.queryRow.sport?.length})` : ``}</label>
						<Select
							options={sports}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.sport = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							value={props.queryRow.sport}
							placeholder="Sports"
							components={animatedComponents}
						/>
					</div>

					<div style={{ maxWidth: '200px' }}>
						<label>Leagues {props.queryRow.league?.length ? `(${props.queryRow.league?.length})` : ``}</label>
						<Select
							options={leagues}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.league = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							// getOptionValue={(option)=> {
							//     return option
							// }}
							value={props.queryRow.league}
							placeholder="Leagues"
							components={animatedComponents}
						/>
					</div>
					<div style={{ maxWidth: '200px' }}>
						<label>Sportbooks {props.queryRow.book?.length ? `(${props.queryRow.book?.length})` : ``}</label>
						<Select
							options={sportsbooks}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.book = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							value={props.queryRow.book}
							placeholder="Sportbooks"
							// controlShouldRenderValue={false}
							components={animatedComponents}
						/>
					</div>
					<div style={{ maxWidth: '200px' }}>
						<label>Sharp Books {props.queryRow.sharp?.length ? `(${props.queryRow.sharp?.length})` : ``}</label>
						<Select
							options={
								props.isAutoRefreshFilter
									? [
											{ value: 'FanDuel', label: 'FanDuel' },
											{ value: 'Pinnacle', label: 'Pinnacle' },
											{ value: 'Circa', label: 'Circa' },
											{ value: 'BookMaker', label: 'BookMaker' },
									  ]
									: sportsbooks
							}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.sharp = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							value={props.queryRow.sharp}
							placeholder="Sharp Books"
							components={animatedComponents}
						/>
					</div>
					<div style={{ maxWidth: '200px' }}>
						<label>Market Types {props.queryRow.marketType?.length ? `(${props.queryRow.marketType?.length})` : ``}</label>
						<Select
							options={[
								{ value: 'Main Markets', label: 'Main Markets' },
								{ value: 'Alternate Markets', label: 'Alternate Markets' },
								{ value: 'Player Props', label: 'Player Props' },
							]}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.marketType = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							value={props.queryRow.marketType}
							placeholder="Market Types"
							components={animatedComponents}
						/>
					</div>

					<div style={{ width: '200px', marginTop: '0.5rem' }}>
						<label>Markets {props.queryRow.market?.length ? `(${props.queryRow.market?.length})` : ``}</label>
						<Select
							options={markets}
							isMulti
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.market = e;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							value={props.queryRow.market}
							placeholder="Markets"
							components={animatedComponents}
						/>
					</div>
					<div>
						<br />
						<MinMaxEvComponent queryRow={props.queryRow} index={props.index} queryRows={props.queryRows} setQueryRows={props.setQueryRows} />
					</div>

					<div>
						<br />
						<MinMaxOddsComponent queryRow={props.queryRow} index={props.index} queryRows={props.queryRows} setQueryRows={props.setQueryRows} />
					</div>
					<div>
						<br />
						<DatePickerComponent queryRow={props.queryRow} index={props.index} queryRows={props.queryRows} setQueryRows={props.setQueryRows} />
					</div>
					<div
						onClick={() => {
							const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
							queryRowsCopy.forEach((row, index) => {
								if (index === props.index) {
									row.hide_alternates = !row.hide_alternates;
								}
							});
							props.setQueryRows(queryRowsCopy);
						}}
						style={{ cursor: 'pointer' }}>
						<br />
						Hide Alternate Lines
						<Checkbox
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.hide_alternates = !row.hide_alternates;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
							checked={props.queryRow.hide_alternates || false}
							style={{ marginLeft: '4px' }}
						/>
					</div>
					<div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<label>Limit ($USD)</label>
							<Tooltip title="Only valid when Pinnacle is the only Sharp Book selected">
								<HelpOutlineIcon style={{ marginLeft: 'auto' }} />
							</Tooltip>
						</div>
						<input
							style={{ width: '179px', border: '1px solid rgb(204, 204, 204)', height: '28px', borderRadius: '5px' }}
							value={props.queryRow.limit || null}
							onChange={(e) => {
								const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
								queryRowsCopy.forEach((row, index) => {
									if (index === props.index) {
										row.limit = e.target.value;
									}
								});
								props.setQueryRows(queryRowsCopy);
							}}
						/>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{props.queryRows.length > 1 && (
						<Tooltip title="Delete search row">
							<div
								className="run-button"
								style={{ alignContent: 'center', padding: '4px', height: 'min-content', marginTop: 'auto', marginBottom: 'auto' }}
								onClick={() => {
									const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
									queryRowsCopy.splice(props.index, 1);
									props.setQueryRows(queryRowsCopy);
								}}>
								<Delete sx={{ color: '#0f1b33', cursor: 'pointer', paddingRight: '3px' }} />
							</div>
						</Tooltip>
					)}
					{props.addRowComponent}
				</div>
			</div>
			{props.index !== props.queryRows.length - 1 && <hr style={{ width: '100%', borderTop: '1px dashed #0f1b33' }} />}
		</div>
	);
};
