import vblogo from '../../vb_logo.svg';
import menuicon from '../../menu_icon.svg';
import question_mark_icon from '../../question_mark_icon.svg';
import profile_icon from '../../profile_icon.svg';
import { useState } from 'react';
import { Button, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from "react-device-detect";

export const TopBar = (props) => {
	const { username } = props;
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<div>
			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<div style={{ backgroundColor: "white", marginTop: '50px', borderRadius: '12px', overflow: "auto", maxHeight: "80%" }}>
					<div style={{ textAlign: "center", fontWeight: "bold", fontSize: "larger" }}>
						Welcome to the ValueBeaver EV Screener!
						<span style={{display: 'flex', justifyContent: "flex-end", cursor: 'pointer'}}><div onClick={() => setIsModalOpen(false)}><CloseIcon /></div></span>
					</div>
					<br />
					<div style={{ marginLeft: "2rem" }}>
						Here are some steps you can take to get the most out of the tools.
					</div>
					<br />
					<div style={{ marginLeft: "2rem" }}>
						<div>
							<div style={{ fontSize: "large" }}>
								Step 1: <span style={{ fontWeight: 'bold' }}>Bankroll</span>
							</div>

							This is the total amount of money you’re planning to invest (and can lose) in sports betting. This amount will be used to calculate recommended bet sizes on plays.
						</div>
						<br />
						<div>
							<div style={{ fontSize: "large" }}>
								Step 2: <span style={{ fontWeight: 'bold' }}>Filters</span>
							</div>
							You can customize the screener to show plays based on your sportsbooks and types of bets you’re interested in. We’ll go through each filter below:
							<ul>
								<li>
									Sports
									<ul><li>Any sport selected will include all leagues for that given sport (e.g. basketball includes NBA, NCAAB, WNBA, etc.)</li></ul>
								</li>
								<li>
									Leagues
									<ul>
										<li>
											This one seems self explanatory :)
										</li>
									</ul>
								</li>
								<li>
									Sportsbooks
									<ul>
										<li>
											Select all the sportsbooks you’d like to bet on.
										</li>
									</ul>
								</li>
								<li>
									Sharp books
									<ul>
										<li>
											Select books you want to use as a “source of truth”. These are the books
										</li>
									</ul>
								</li>
								<li>
									Sharp books
									<ul>
										<li>
											Select books you want to use as a “source of truth”. These are the books you’re comparing against to find mispriced lines on your own sportsbooks.
										</li>
									</ul>
								</li>
								<li>
									Market types
									<ul>
										<li>
											Filter the types of lines you see.
										</li>
									</ul>
								</li>
								<li>
									Market types
									<ul>
										<li>
											Filter the types of lines you see. Main markets includes spreads, totals, and moneylines.
											Alternate markets includes things like 1st half spreads/totals, team totals, etc.
											Player props include markets centred on an individual player’s performance.
										</li>
									</ul>
								</li>
								<li>
									Markets
									<ul>
										<li>
											Lets you filter by specific markets if you want to narrow in on one or a few things in particular.
										</li>
									</ul>
								</li>
								<li>
									Min/max EV
									<ul>
										<li>
											Select a minimum EV threshold to give yourself enough margin of safety to prevent bad plays. Select a maximum EV if you don’t want to play huge outliers (for avoid getting limited or other reasons)
										</li>
									</ul>
								</li>
								<li>
									Min/max odds
									<ul>
										<li>
											Set these filters if you want to avoid huge favourites or underdogs.
										</li>
									</ul>
								</li>
								<li>
									Date range
									<ul>
										<li>
											Filter for games between selected dates.
										</li>
									</ul>
								</li>
								<li>
									Hide Alternate Lines
									<ul>
										<li>
											Only shows mainlines for a given event. E.g. if a football spread is +/-3.5 close to even money, no results for the 10.5 alternate line would show up.
											You can set multiple rows of filters for a query. Use the + icon to add a row and the garbage icon to remove a row.
										</li>
									</ul>
								</li>
							</ul>

							<span style={{ fontWeight: 'bold' }}>Save</span> your query by typing a name on the box on the upper left of the page, then hitting the <span style={{ fontWeight: 'bold' }}>Save</span> button at the top right of the filters menu.
						</div>
						<br />
						<div style={{ marginBottom: '2rem' }}>
							<div style={{ fontSize: "large" }}>
								Step 3: <span style={{ fontWeight: 'bold' }}>Discord Notifications</span>
							</div>
							<div>
								Get notified of plays instantly when line movement is detected!
								To get set up, follow these steps:
								<ol>
									<li>
										Click the notifications button

									</li>
									<li>
										Enter your Discord username
									</li>
									<li>
										Click the link that gets sent to your Discord DMs
									</li>
									<li>
										Click the “on” switch on the notifications menu
									</li>
									<li>
										Set your filters by clicking filters, then notifications and filling out your query the same way as described in step 2.
									</li>
									<li>Click “Save Notifications”.</li>
									Enjoy!
								</ol>
								<span style={{ fontWeight: 'bold' }}>NOTE: </span>We’d recommend a bit higher of an EV threshold for notifications as you might receive a LOT of notifications otherwise.
							</div>
							<br />
							<div>
								<div style={{ fontSize: "large" }}>
									Step 4: <span style={{ fontWeight: 'bold' }}>Using the tools</span>
								</div>
								Once you’ve set your filters, click the play button to get your first plays! There are several items that can help you place your bets more accurately and quicker. Below are descriptions of some of the useful features:
								<ul>
									<li>
										Price
										<ul>
											<li>
												The current price of the market on the sportsbook you’re betting into.
											</li>
										</ul>
									</li>
									<li>
										Sharp Price
										<ul>
											<li>
												The current price of both sides of the market on the sharp book(s) you’ve selected. If you have multiple sharp books selected, this price will be an average.
												E.g. There is a play listed for an OVER 212.5 NBA total at -110.  The sharp line presented is -130 / +110. The number before the slash is the OVER 212.5 on the sharp book(s). The number after the slash is the UNDER 212.5 on the sharp book(s).
											</li>
										</ul>
									</li>
									<li>
										Line direction arrows
										<ul>
											<li>
											Displays the direction of the most recent line movement on the corresponding book.
												<br />
												The arrow would face DOWN if a line moves from -110 to -120.
												The arrow would face UP if a line moves from -120 to -110.
												There would be a dash if a line has not moved.
											</li>
										</ul>
									</li>
								
								</ul>
							</div>
						</div>


					</div>

				</div>
			</Modal>
			<div style={{ backgroundColor: '#0f1b33', height: '60px', display: 'flex', flexDirection: 'row', width: isMobile ? '1225px' : '' }}>
				<img style={{ marginLeft: '1rem', cursor: 'pointer' }} width={40} src={vblogo} alt='valuebeaver logo' />
				<div style={{ color: 'white', display: 'flex', flexDirection: 'row' }}>
					{/* <div style={{ alignContent: "center", marginLeft: '4rem', cursor: 'pointer' }}>
                    <img src={menuicon} />
                </div> */}

					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						EV Scanner
					</div>

					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						Sportsbook Screener
					</div>
					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						Future Updates
					</div>
				</div>
				<div style={{ marginLeft: 'auto', marginRight: '1rem', display: 'flex' }}>
					<div style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto' }}>{props.isSignedIn && username}</div>

					<div>
						<img style={{ cursor: 'pointer', marginRight: '0.5rem' }} src={question_mark_icon} className="button" onClick={() => {
							setIsModalOpen(true)
						}} />
						<img
							height={40}
							style={{ cursor: 'pointer' }}
							src={profile_icon}
							className="button"
							onClick={() => {
								setIsProfileOpen(!isProfileOpen);
							}}
						/>
					</div>
				</div>
			</div>
			<div style={{ height: '6px', backgroundColor: '#dc8d16', width: isMobile ? '1225px' : '' }} />
			{isProfileOpen && props.isSignedIn && (
				<div>
					<div style={{ marginLeft: 'auto', width: '80px', marginTop: '-15px', backgroundColor: '#0f1b33', borderRadius: '12px' }}>
						<Button
							onClick={() => {
								localStorage.clear();
								setIsProfileOpen(false);
								props.setIsSignedIn(false);
							}}>
							Logout
						</Button>
					</div>
				</div>
			)}
			{!isProfileOpen && props.isSignedIn && <div style={{ height: '21.5px' }}></div>}
		</div>
	);
};
