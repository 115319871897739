import DatePicker from 'react-datepicker';
import './DatePicker.css';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerComponent = (props) => {
	return (
		// TODO: save the date difference instead of the actual date
		// instead of saying specific date (3 days from now), the value will be 3, and interpreted from the calculator as meaning 3 days in advance
		// So the user returning wont have to reset the date filter (it defaults to the date difference)
		<div style={{ marginRight: '1rem', marginTop: '0.5rem' }}>
			<div style={{ display: 'flex', flexDirection: 'column', marginRight: '3.4rem', maxWidth: '180px' }}>
				<DatePicker
					wrapperClassName="customDatePickerWidth"
					selected={new Date(props.queryRow.date)}
					onChange={(e) => {
						const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows));
						queryRowsCopy.forEach((row, index) => {
							if (index === props.index) {
								row.date = new Date(e);

								const diffTime = Math.abs(new Date() - row.date);

								const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

								row.dateDifference = diffDays + 1;
							}
						});
						props.setQueryRows(queryRowsCopy);
					}}
					minDate={new Date()}
				/>
				Date until
			</div>
		</div>
	);
};
