import { TextField, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from "react-device-detect";

function useOutsideAlerter(ref, setIsEditing) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsEditing(false);
				// alert('You clicked outside of me!');
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, setIsEditing]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
	const { setIsEditing } = props;
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, setIsEditing);

	return <div ref={wrapperRef}>{props.children}</div>;
}

export const EvScannerBar = (props) => {
	const { bankroll, setBankroll } = props;
	const [isEditing, setIsEditing] = useState(false);

	return (
		<div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', width: isMobile ? '1120px': 'auto' }}>
			<h1 style={{ marginTop: 'auto', marginLeft: '2rem' }}>EV Scanner</h1>
			<div style={{ marginLeft: 'auto', marginRight: '2rem' }}>
				<h3
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (!isEditing) {
							setIsEditing(true);
						}
					}}>
					{!isEditing && (
						<>
							<Tooltip title="Click to adjust your bankroll. To save, go to Filters and hit 'Save'. This value affects your recommended bet size (hover over quarter kelly column to view)">
								<div>{bankroll ? `$${bankroll}` : ''}</div>
							</Tooltip>
						</>
					)}
					{isEditing && (
						<>
							<OutsideAlerter setIsEditing={setIsEditing}>
								<TextField
									value={bankroll}
									onChange={(e) => {
										setBankroll(e.target.value);
										console.log('e: ', e.target.value);
									}}
								/>
							</OutsideAlerter>
						</>
					)}
					<div>Bankroll</div>
				</h3>
			</div>
		</div>
	);
};
