import { useState } from 'react'
import { QueryRow } from './QueryRow';
import saveIcon from '../../../save_icon.svg'
import './FiltersBar.css'
import { Modal, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { deleteQuery, setDefaultQuery } from '../../../api/saveNotificationSettings';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { isMobile } from "react-device-detect";


const retrieveUsernameAndPassword = () => {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const loginData = localStorage.getItem("loginData")
  return { username: username, password: password, loginData: JSON.parse(String(loginData)) }
}

export const FiltersBar = (props) => {
  // console.log('props: ', props);
  const { selectedQuery, setSelectedQuery, queryRows, setQueryRows, setIsNotificationFiltersOpen, setIsFiltersOpen } = props;
  const [isModalOpen, setIsModalOpen] = useState(false)
  // console.log("🚀 ~ FiltersBar ~ isModalOpen:", isModalOpen)
  // console.log(props)
  const { username, password } = retrieveUsernameAndPassword()

  return <div>
    <div>
      <div style={{ display: 'flex', flexDirection: "row", backgroundColor: "#0E1B33", marginLeft: "2rem", marginRight: '2rem', borderRadius: '12px', marginTop: '2rem', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, width: isMobile ? '1120px': 'auto' }}>
        <h3 style={{ marginLeft: '1rem', userSelect: "none", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: 'none' }}>
          Filters
        </h3>
        <div style={{ alignContent: 'center', marginLeft: '5rem', cursor: "pointer" }} onClick={(e) => {
          if (!isModalOpen) {
            setIsModalOpen(true)
          }
        }}>
          <Tooltip title="Delete query">
            <DeleteIcon style={{ height: 37, width: 37, marginTop: 'auto', marginBottom: 'auto' }} />
          </Tooltip>

          <Modal open={isModalOpen} onClose={async () => { await setIsModalOpen(false); }}>
            <div style={{ backgroundColor: "white", position: "absolute", insetArea: "center", padding: "30px", borderRadius: "16px" }}>
              Confirm delete: {selectedQuery?.[0]?.name || queryRows?.[0]?.name}
              <div className='button-red' style={{ marginTop: "2rem", justifySelf: "center", padding: "8px", borderRadius: "16px", cursor: "pointer" }} onClick={async () => {
                await deleteQuery(username, password, selectedQuery?.[0]?.name || queryRows?.[0]?.name)
                window.location.reload(false)
              }}>
                <span style={{ marginBottom: 10 }}>Confirm! </span><HighlightOffIcon style={{ height: 25, width: 35, marginTop: 10 }} />
              </div>
            </div>
          </Modal>
        </div>
        {
          !selectedQuery || !selectedQuery?.[0].default && <div style={{ alignContent: 'center', marginLeft: '5rem', cursor: "pointer" }} onClick={async (e) => {
            await setDefaultQuery(username, password, selectedQuery?.[0]?.name || queryRows?.[0]?.name)
          }}>
            <Tooltip title="Set default query">
              <div className='run-button' style={{ display: 'flex' }}>
                <div style={{ alignContent: "center" }}>Set Default Query
                </div>
                <DomainVerificationIcon style={{ height: 37, width: 37, marginLeft: "10px", marginTop: 'auto', marginBottom: 'auto' }} />
              </div>

            </Tooltip>


          </div>
        }

        <div style={{ marginLeft: 'auto', marginRight: "1rem", backgroundColor: "white", borderRadius: '8px', height: "min-content", color: "black", marginTop: "auto", marginBottom: 'auto', cursor: "pointer" }}>
          <p style={{ marginTop: '5px', marginBottom: "5px", padding: '4px' }} >Website</p>
        </div>
        <div onClick={() => {
          setIsFiltersOpen(false)
          setIsNotificationFiltersOpen(true)
        }} style={{ marginRight: "1rem", borderRadius: '8px', height: "min-content", marginTop: "auto", marginBottom: 'auto', cursor: "pointer" }}>
          <p style={{ marginTop: '5px', marginBottom: "5px" }}>Notifications</p>
        </div>
        <div className='button' style={{ marginRight: "1rem", borderRadius: '8px', height: "min-content", marginTop: "auto", marginBottom: 'auto', cursor: "pointer", display: 'flex', flexDirection: 'row', marginLeft: "3rem" }}
          onClick={() => {
            props.saveSettings()
          }}
        >
          <p style={{ marginTop: '5px', marginBottom: "5px", marginRight: '0.25rem' }}>Save</p>
          <img className='filter' src={saveIcon} />
        </div>
      </div>
      <div style={{ backgroundColor: "#DC8D16", height: "6px", marginLeft: "2rem", marginRight: '2rem', borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, width: isMobile ? '1120px': 'auto' }}>
      </div>
    </div>

    {
      !selectedQuery && queryRows.map((queryRow, index) => <QueryRow index={index} setQueryRows={setQueryRows} queryRows={queryRows} queryRow={queryRow} last={index === queryRows.length - 1} filterOptions={props.filterOptions} addRowComponent={<Tooltip title="Add new search row">
        <div className='run-button' style={{ marginLeft: "auto", height: 'min-content', alignSelf: "center", color: "#0f1b33", marginRight: '1rem', position: 'relative', right: '10px' }}
          onClick={() => {
            const queryRowsCopy = JSON.parse(JSON.stringify(queryRows));
            queryRowsCopy.push({ league: [], date: new Date(new Date().setDate(new Date().getDate() + 10)), minMaxOdds: [-1000, 1000], minMaxEv: [0, 100], minMaxEvFilterOpen: false, minMaxOddsFilterOpen: false, sport: [], book: [], sharpBook: [], market: [] })
            setQueryRows(queryRowsCopy)
          }}
        >
          +
        </div>
      </Tooltip>} isAutoRefreshFilter={props.isAutoRefreshFilter} />)
    }
    {
      selectedQuery && selectedQuery.map((queryRow, index) => <QueryRow index={index} setQueryRows={setSelectedQuery} queryRows={selectedQuery} queryRow={queryRow} last={index === selectedQuery.length - 1} filterOptions={props.filterOptions} isAutoRefreshFilter={props.isAutoRefreshFilter} addRowComponent={<Tooltip title="Add new search row">
        <div className='run-button' style={{ marginLeft: "auto", height: 'min-content', alignSelf: "center", color: "#0f1b33", marginRight: '1rem', position: 'relative', right: '10px' }}
          onClick={() => {

            // TODO: setSelectedQuery should only include the id of the selected queryRows
            const queryRowsCopy = JSON.parse(JSON.stringify(selectedQuery));
            queryRowsCopy.push({ league: [], date: new Date(new Date().setDate(new Date().getDate() + 10)), minMaxOdds: [-1000, 1000], minMaxEv: [0, 100], minMaxEvFilterOpen: false, minMaxOddsFilterOpen: false, sport: [], book: [], sharpBook: [], market: [] })
            setSelectedQuery(queryRowsCopy)
          }}
        >
          +
        </div>
      </Tooltip>} />)
    }
    <div style={{ marginLeft: '2rem', marginRight: "2rem", display: "flex", flexDirection: "row" }}>
    </div>
  </div>
}